/* Tailwind CSS classes can be used directly here */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes launch {
  0% { transform: translateY(0); }
  100% { transform: translateY(-150vh); }
}

.rocket-shake {
  animation: shake 0.5s ease-in-out infinite;
}

.rocket-launch {
  animation: launch 2s ease-in-out forwards;
}

@keyframes rocketEntrance {
  0% {
    transform: translateY(100vh);
  }
  50% {
    transform: translateY(0);
  }
  65% {
    transform: translateY(3vh);
  }
  75% {
    transform: translateY(5vh);
  }
  80% {
    transform: translateY(6vh);
  }
  85% {
    transform: translateY(8vh);
  }
  90% {
    transform: translateY(9vh);
  }
  100% {
    transform: translateY(10vh);
  }
}

.rocket-enter {
  animation: rocketEntrance 2s ease-out forwards;
}